import { Trans, t } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import LandingLayout from "../../components/landings/LandingLayout";
import HeroLanding from "../../components/HeroLanding";
import SecondaryButton from "../../components/SecondaryButton";
import { theme } from "../../themeV2";
import ProductModal from "../../components/ProductModal";
import DefaultSection from "../../components/landings/DefaultSection";
import CardColor from "../../components/CardColor";
import Container from "../../components/Container";
import PreTitle from "../../components/landings/PreTitle";
import heroImg from "../../images/solutions/distribution-platform/hero.png";
import sec1Icon1 from "../../images/solutions/distribution-platform/sec-1_icon-purple.png";
import sec1Icon2 from "../../images/solutions/distribution-platform/sec-1_icon-orange.png";
import sec1Icon3 from "../../images/solutions/distribution-platform/sec-1_icon-blue.png";
import sec1Icon4 from "../../images/solutions/distribution-platform/sec-1_icon-green.png";
import sec2IconYellow from "../../images/solutions/distribution-platform/sec-2_icon-yellow.svg";
import sec2IconPurple from "../../images/solutions/distribution-platform/sec-2_icon-purple.svg";
import sec2IconGreen from "../../images/solutions/distribution-platform/sec-2_icon-green.svg";
import sec2IconBlue from "../../images/solutions/distribution-platform/sec-2_icon-blue.svg";
import sec3Img from "../../images/solutions/distribution-platform/sec-3.png";

const metadata = {
  title: "Distribution Platform",
  description:
    "Experience the future of investment distribution with Allfunds. Our integrated platform provides unparalleled access to mutual funds, private markets, and ETPs, all within a single, user-friendly ecosystem. ",
};

const mainColor = theme.palette.colors.blueLight.main;

const SEC_1_ITEMS = [
  {
    icon: sec1Icon1,
    title: t`Unmatched Global Reach:`,
    text: t`Access a vast universe of funds with a single contract and manage every aspect of your investments with ease such as order details,  cash management, transaction data...`,
  },
  {
    icon: sec1Icon2,
    title: t`Seamless Integration`,
    text: t`Enjoy a smooth experience across all Allfunds platforms, connecting your long-only fund distribution with private markets and ETPs.`,
  },
  {
    icon: sec1Icon3,
    title: t`Market-Leading Efficiency`,
    text: t`Benefit from our market-leading fund dealing platform, offering utmost efficiency with the asset protection of a leading custodian.`,
  },
  {
    icon: sec1Icon4,
    title: t`Open Architecture`,
    text: t`Access an open architecture in any fund range and fund domicile around the world. We are independent, with no proprietary distribution activity.`,
  },
];

const SEC_2_CARDS_ITEMS = [
  {
    icon: sec2IconPurple,
    color: theme.palette.colors.purple.main,
    title: t`Open Architecture`,
    description: t`Penetrate the ETP open architecture market, which has surpassed $2 trillion of assets domiciled in Europe.`,
  },
  {
    icon: sec2IconYellow,
    color: theme.palette.colors.yellow.main,
    title: t`Leverage Existing Channels`,
    description: t`Grow your share of wallet by leveraging your existing distributor channels as they increase their usage of ETPs.`,
  },
  {
    icon: sec2IconGreen,
    color: theme.palette.colors.green.main,
    title: t`Unlock New Channels`,
    description: t`Target new relationships and welcome more clients into the Allfunds family through our ETP platform.`,
  },
  {
    icon: sec2IconBlue,
    color: theme.palette.colors.blueDark.main,
    title: t`Enhanced Liquidity `,
    description: t`Provide your clients with access to a wide variety of ETPs, improve liquidity delivery, and optimize distribution strategies.`,
  },
];

function DistributionPlatform() {
  const [showModal, setShowModal] = useState(false);
  const [subTitle, setSubTitle] = useState(null);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }, key) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          transform: {
            md: key % 2 === 0 ? "translateY(0)" : "translateY(24px)",
          },
        }}
      >
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  return (
    <LandingLayout
      seoNoIndex
      seoTitle={metadata.title}
      seoDescription={metadata.description}
    >
      <HeroLanding
        title={<Trans>One platform for funds,  private markets, and ETPs distribution</Trans>}
        description={
          <Trans>
            Experience the future of investment distribution with Allfunds. Our integrated platform provides unparalleled access to mutual funds, private markets, and ETPs, all within a single, user-friendly ecosystem.
            <br />
            <br />
            Benefit from increased efficiency, reduced administrative burden, and enhanced investment opportunities.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo("distribution-platform")}
        multimedia={heroImg}
        widthImage="100%"
        kind="Invest. Everything. Centralize"
        kindColor={mainColor}
      />
      <DarkStack
        component="span"
        borderRadius="36px"
        backgroundColor={theme.palette.colors.blue.dark}
      >
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          containerPy={5}
          pretitle={t`Long Only Funds`}
          pretitleColor={mainColor}
          color={theme.palette.colors.white.main}
          title={t`Access the World's Funds`}
          description={
            <>
              <Typography width={{ md: "89%" }} style={{ marginLeft: "auto", marginRight: "auto" }}>
                <Trans>
                  Our B2B platform supports an open-architecture model, featuring over 3,100 fund groups and approximately €1.5 trillion in Assets under Administration (AuA). With over 24 years of experience, we are a trusted leader in the industry.
                </Trans>
              </Typography>
              <Box display="flex" justifyContent="center">
                {renderRequestButton("Long Only Funds")}
              </Box>
              <Grid
                container
                spacing={{ xs: 2, md: 3}}
                ml={{ xs: "-24px!important", md: "-36px!important" }}
                mt={1}
                pb={{ md: 2 }}
                order={{ xs: 0, md: 1 }}
              >
                {SEC_1_ITEMS.map((item, key) => (
                  <Grid item key={key} md={3} sm={6} xs={12}>
                    <Stack spacing={1} textAlign="left">
                      <img
                        src={item.icon}
                        alt="Distribution Platform - Allfunds"
                        width="46"
                        height="46"
                        style={{ objectFit: "contain" }}
                      />
                      <Typography fontWeight="700" lineHeight="1.1">
                        {item.title}
                      </Typography>
                      <Typography>{item.text}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </>
          }
        />
      </DarkStack>
      <Container component="section" py={{ xs: 2, md: 10, lg: 8 }}>
        <Grid container spacing={5} alignItems="center" flexDirection="row-reverse">
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1} component="header">
                <PreTitle
                  title={t`ETP platform`}
                  color={mainColor}
                  align="left"
                  />
                <Typography variant="h2">
                  <Trans>The Future of ETP Distribution</Trans>
                </Typography>
              </Stack>
              <Typography><Trans>
                Our one-stop-shop solution for Exchange-Traded Products (ETPs) provides clients with access to a wide variety of ETPs, enhances liquidity delivery, and optimizes distribution strategies.
              </Trans></Typography>
              <Box display={{ xs: "none", sm: "block"}}>
                {renderRequestButton("ETP platform")}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            >
            <Grid container spacing={{ xs: 1, md: 2 }}>
              {SEC_2_CARDS_ITEMS.map((item, key) => renderCard(item, key))}
            </Grid>
            <Box display={{ xs: "blcok", sm: "none"}} mt={2}>
              {renderRequestButton("ETP platform")}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <DarkStack
        component="span"
        backgroundColor={theme.palette.colors.blue.dark}
        borderRadius="48px 48px 0 0"
        py={{ xs: 2, sm: 4, md: 5 }}
      >
        <DefaultSection
          containerPy={0}
          direction={{ md: "row-reverse" }}
          color={theme.palette.colors.white.main}
          pretitle={t`Private Markets Platform`}
          pretitleColor={mainColor}
          title={t`Unlocking Private Markets`}
          description={
            <>
              <Typography>
                <Trans>
                  Launched in the first half of 2023, our open-architecture model for private markets includes more than 120 Alternative Asset Managers and over €16 billion in AuA.
                </Trans>
              </Typography>
              <ul>
                <li style={{ marginBottom: "12px" }}>
                  <Trans>
                    <b>Growing Momentum:</b> Be part of a rapidly expanding segment. Allfunds has €16 billion in Alternatives AuA, of which €7.1 billion is in distribution, growing at 27% year-to-date.
                  </Trans>
                </li>
                <li style={{ marginBottom: "12px" }}>
                  <Trans>
                    <b>Expanding Network:</b> Join a network of over 250 distributors already investing in alternative investments via Allfunds.
                  </Trans>
                </li>
                <li style={{ marginBottom: "12px" }}>
                  <Trans>
                    <b>AAS proposition :</b> Expand our reach to new distributors, with 20+ joining exclusively to offer private assets to their private wealth customers
                  </Trans>
                </li>
                <li>
                  <Trans>
                    <b>Increasing Supply:</b> Capitalize on the growing demand for alternatives with access to funds launched 12-18 months ago and over 50 new product launches expected in 2024.
                  </Trans>
                </li>
              </ul>
            </>
          }
          multimedia={sec3Img}
          buttons={renderRequestButton("Private Markets Platform")}
        />
      </DarkStack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Distribution Platform"
          product="distribution-platform"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

const DarkStack = styled(Stack)`
  .sec-1_tab img {
    @media screen and (min-width: 1200px) {
      padding-right: 24px;
    }
   };
  
  p:not(.preTitle) {color: ${theme.palette.colors.white.main};
`;

export default DistributionPlatform;
